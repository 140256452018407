import { useCallback } from 'react';
import { FormProps } from 'react-final-form';

import { useNotification } from 'lux/components/Notifications/Provider';
import { EmployerSearchPublicationSettings } from 'lux/models/employerSearchPublicationSettings';
import fetcher from 'lux/modules/fetcher';
import {
    searchPublicationEditError,
    searchPublicationEditSuccess,
} from 'lux/pages/EmployerSearchPublication/components/SearchPublicationSettings';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

const URL = '/employer/settings/search-publication';
declare global {
    interface FetcherPostApi {
        [URL]: {
            queryParams: void;
            body: EmployerSearchPublicationSettings;
            response: { success: boolean };
        };
    }
}

interface UseSubmitHandlerHook {
    (): {
        handleSubmit: FormProps['onSubmit'];
    };
}

const useSubmitHandler: UseSubmitHandlerHook = () => {
    const { addNotification } = useNotification();

    const handleSubmit: FormProps['onSubmit'] = useCallback(
        async (values): Promise<Record<string, string> | undefined> => {
            let result;
            try {
                result = await fetcher.postFormData(URL, values as EmployerSearchPublicationSettings);
            } catch (error) {
                defaultRequestErrorHandler(error, addNotification);
                return undefined;
            }

            const notificationType = result.data.success ? searchPublicationEditSuccess : searchPublicationEditError;
            addNotification(notificationType);

            return undefined;
        },
        [addNotification]
    );

    return { handleSubmit };
};

export default useSubmitHandler;
