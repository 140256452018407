import { Field } from 'react-final-form';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { FormItem } from 'bloko/blocks/form';
import Radio from 'bloko/blocks/radio';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import FieldWrapper from 'lux/pages/EmployerSearchPublication/components/common/FieldWrapper';
import LabelWrapper from 'lux/pages/EmployerSearchPublication/components/common/LabelWrapper';

const TrlKeys = {
    label: 'employer.settings.searchpublication.d',
    JSXEDITOR: 'employer.settings.searchpublication.hh',
    CKEDITOR: 'employer.settings.searchpublication.ck',
    NONE: 'employer.settings.searchpublication.no',
};

const EDITORS = ['JSXEDITOR', 'CKEDITOR', 'NONE'] as const;

const Editor: TranslatedComponent = ({ trls }) => {
    const { htmlEditor: initialValue } = useSelectorNonNullable((state) => state.employerSearchPublicationSettings);

    return (
        <div>
            <VSpacing base={2} />
            <LabelWrapper>{trls[TrlKeys.label]}</LabelWrapper>
            <FieldWrapper>
                <Field name="htmlEditor" initialValue={initialValue}>
                    {({ input }) => {
                        return (
                            <>
                                {EDITORS.map((editorType) => (
                                    <FormItem key={editorType} baseline>
                                        <Radio
                                            checked={input.value === editorType}
                                            name="htmlEditor"
                                            onChange={() => input.onChange(editorType)}
                                            onFocus={input.onFocus}
                                            onBlur={input.onBlur}
                                            value={editorType}
                                        >
                                            {trls[TrlKeys[editorType]]}
                                        </Radio>
                                    </FormItem>
                                ))}
                            </>
                        );
                    }}
                </Field>
            </FieldWrapper>
        </div>
    );
};

export default translation(Editor);
