import { Field, useField } from 'react-final-form';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { EDITOR_TOOLBAR_DEFAULT, EDITOR_TOOLBAR_SIMPLE } from 'Modules/CKEditor/common-settings';
import Editor from 'lux/components/Editor';
import translation from 'lux/components/translation';
import { EmployerSearchPublicationSettings } from 'lux/models/employerSearchPublicationSettings';

const EDITOR_TOOLBAR = {
    CKEDITOR: EDITOR_TOOLBAR_DEFAULT,
    JSXEDITOR: EDITOR_TOOLBAR_SIMPLE,
} as const;

const TrlKeys = {
    label: 'vacancyCreate.ckeditor.format.tag.h2',
    tagH2: 'vacancyCreate.ckeditor.format.tag.h2',
    tagDiv: 'vacancyCreate.ckeditor.format.tag.div',
};

const VacancyTemplate: TranslatedComponent = ({ trls }) => {
    const { templateVacancy: initialValue } = useSelectorNonNullable(
        (state) => state.employerSearchPublicationSettings
    );
    const editorType = useField<EmployerSearchPublicationSettings['htmlEditor']>('htmlEditor').input.value;

    return (
        <div>
            <VSpacing base={2} />
            <Column l="10" m="10" s="8" xs="4">
                <Field name="templateVacancy" initialValue={initialValue}>
                    {({ input }) => {
                        return (
                            <div className="html-editor">
                                {editorType === 'NONE' ? (
                                    <textarea
                                        className="html-editor__textarea"
                                        value={input.value}
                                        onChange={input.onChange}
                                        onBlur={input.onBlur}
                                        onFocus={input.onFocus}
                                    />
                                ) : (
                                    <Editor
                                        key={editorType}
                                        toolbar={EDITOR_TOOLBAR[editorType]}
                                        overrideLang={{
                                            format: {
                                                tag_h2: trls[TrlKeys.tagH2], // eslint-disable-line camelcase
                                                tag_div: trls[TrlKeys.tagDiv], // eslint-disable-line camelcase
                                            },
                                        }}
                                        {...input}
                                    />
                                )}
                            </div>
                        );
                    }}
                </Field>
            </Column>
        </div>
    );
};

export default translation(VacancyTemplate);
