import { FC, PropsWithChildren, ReactNode } from 'react';

import Column from 'bloko/blocks/column';
import { FormLegend } from 'bloko/blocks/form';

const LabelWrapper: FC<
    {
        hideOnMobile?: boolean;
        BottomContent?: ReactNode;
    } & PropsWithChildren
> = ({ hideOnMobile, children, BottomContent = null }) => {
    return (
        <Column l="4" m="4" s={hideOnMobile ? '0' : '8'} xs={hideOnMobile ? '0' : '4'}>
            <FormLegend>{children}</FormLegend>
            {BottomContent}
        </Column>
    );
};

export default LabelWrapper;
