import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import Tabs, { Tab } from 'bloko/blocks/tabs';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'lux/components/translation';

import EmployerManager from 'lux/components/EmployerSettingsMenu/EmployerManager';
import MenuHeader from 'lux/components/EmployerSettingsMenu/menuHeader';

const EmployerSettingsMenu = ({ menu, employerManagersLink, trls }) => (
    <Column xs="4" s="8" m="12" l="16">
        <MenuHeader employerManagersLink={employerManagersLink} />
        <VSpacing base={3} />
        <EmployerManager />
        <Gap top bottom>
            <Tabs>
                {menu.map(({ url, active, name, ...tab }) => (
                    <Tab
                        id={url}
                        disabled={active}
                        to={url}
                        active={active}
                        key={url}
                        data-qa={tab['data-qa']}
                        Element={Link}
                    >
                        {trls[EmployerSettingsMenu.trls[name]]}
                    </Tab>
                ))}
            </Tabs>
        </Gap>
    </Column>
);

EmployerSettingsMenu.propTypes = {
    /** Массив объектов меню { url: string, active: boolean, name: string, ... } */
    menu: PropTypes.array,
    /** ссылка на страницу менеджеров вакансий (employerManagersLink) */
    employerManagersLink: PropTypes.string,
    trls: PropTypes.object,
};

EmployerSettingsMenu.trls = {
    employerMenuPersonalSettings: 'employer.settingsmenu.personal',
    employerMenuChangePassword: 'employer.settingsmenu.changePassword',
    employerMenuSearchPublication: 'employer.settingsmenu.searchpublication',
    employerMenuVacancyTemplates: 'employer.settingsmenu.vacancytemplates',
    employerMenuVacancyConstructor: 'employer.settingsmenu.vacancyconstructor',
    employerMenuEmailSettings: 'employer.settingsmenu.email',
    employerMenuSubscriptionSettings: 'employer.settingsmenu.subscriptions',
    employerMenuApplications: 'employer.settingsmenu.applications',
    employerMenuManagerQuotas: 'employer.settingsmenu.managerquotas',
    employerMenuVideoVacancy: 'employer.settingsmenu.video_vacancy',
};

export const EmployerSettingsMenuRaw = translation(EmployerSettingsMenu);

export default connect((state) => ({
    menu: state.employerSettingsMenu.menuItems,
    employerManagersLink: state.employerSettingsMenu.employerManagersLink,
}))(EmployerSettingsMenuRaw);
