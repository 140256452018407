import { Field } from 'react-final-form';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { FormItem } from 'bloko/blocks/form';
import Radio from 'bloko/blocks/radio';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import FieldWrapper from 'lux/pages/EmployerSearchPublication/components/common/FieldWrapper';
import LabelWrapper from 'lux/pages/EmployerSearchPublication/components/common/LabelWrapper';

const TrlKeys = {
    label: 'employer.settings.searchpublication.c',
    AZN: 'employer.settings.searchpublication.cur.AZN',
    BYR: 'employer.settings.searchpublication.cur.BYR',
    EUR: 'employer.settings.searchpublication.cur.EUR',
    KZT: 'employer.settings.searchpublication.cur.KZT',
    RUR: 'employer.settings.searchpublication.cur.RUR',
    UAH: 'employer.settings.searchpublication.cur.UAH',
    USD: 'employer.settings.searchpublication.cur.USD',
    UZS: 'employer.settings.searchpublication.cur.UZS',
    GEL: 'employer.settings.searchpublication.cur.GEL',
    KGS: 'employer.settings.searchpublication.cur.KGS',
};

const Currency: TranslatedComponent = ({ trls }) => {
    const currencies = useSelectorNonNullable((state) => state.currencies.list);
    const { currencyCode: initialCurrency } = useSelectorNonNullable(
        (state) => state.employerSearchPublicationSettings
    );

    return (
        <div>
            <VSpacing base={2} />
            <LabelWrapper>{trls[TrlKeys.label]}</LabelWrapper>
            <FieldWrapper>
                <Field name="currencyCode" initialValue={initialCurrency}>
                    {({ input }) => {
                        return (
                            <>
                                {currencies.map(({ code }) => (
                                    <FormItem key={code} baseline>
                                        <Radio
                                            checked={input.value === code}
                                            name="currencyCode"
                                            onChange={() => input.onChange(code)}
                                            onFocus={input.onFocus}
                                            onBlur={input.onBlur}
                                            value={code}
                                        >
                                            {trls[TrlKeys[code]]}
                                        </Radio>
                                    </FormItem>
                                ))}
                            </>
                        );
                    }}
                </Field>
            </FieldWrapper>
        </div>
    );
};

export default translation(Currency);
