import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import EmployerSettingsMenu from 'lux/components/EmployerSettingsMenu';
import PageLayout from 'lux/components/PageLayout';
import translation from 'lux/components/translation';

import SearchPublicationForm from 'lux/pages/EmployerSearchPublication/SearchPublicationForm';

const TrlKeys = {
    pageTitle: 'settings.employer.title',
};

const EmployerSearchPublication: TranslatedComponent = ({ trls }) => {
    return (
        <PageLayout title={trls[TrlKeys.pageTitle]}>
            <EmployerSettingsMenu />
            <SearchPublicationForm />
        </PageLayout>
    );
};

export default translation(EmployerSearchPublication);
