import { Field } from 'react-final-form';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { FormItem } from 'bloko/blocks/form';
import Radio from 'bloko/blocks/radio';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import FieldWrapper from 'lux/pages/EmployerSearchPublication/components/common/FieldWrapper';
import LabelWrapper from 'lux/pages/EmployerSearchPublication/components/common/LabelWrapper';

const TrlKeys = {
    label: 'employer.settings.searchpublication.sort',
    LAST_CHANGE_TIME_EXCEPT_EMPLOYER_INBOX: 'employer.settings.searchpublication.sort.date',
    RELEVANCE: 'employer.settings.searchpublication.sort.relevance',
};

const SORTING_TYPES = ['LAST_CHANGE_TIME_EXCEPT_EMPLOYER_INBOX', 'RELEVANCE'] as const;

const Sorting: TranslatedComponent = ({ trls }) => {
    const { vacancyResponseRankType: initialValue } = useSelectorNonNullable(
        (state) => state.employerSearchPublicationSettings
    );

    return (
        <div>
            <VSpacing base={2} />
            <LabelWrapper>{trls[TrlKeys.label]}</LabelWrapper>
            <FieldWrapper>
                <Field name="vacancyResponseRankType" initialValue={initialValue}>
                    {({ input }) => {
                        return (
                            <>
                                {SORTING_TYPES.map((sortingType) => (
                                    <FormItem key={sortingType} baseline>
                                        <Radio
                                            checked={input.value === sortingType}
                                            name="vacancyResponseRankType"
                                            onChange={() => input.onChange(sortingType)}
                                            onFocus={input.onFocus}
                                            onBlur={input.onBlur}
                                            value={sortingType}
                                        >
                                            {trls[TrlKeys[sortingType]]}
                                        </Radio>
                                    </FormItem>
                                ))}
                            </>
                        );
                    }}
                </Field>
            </FieldWrapper>
        </div>
    );
};

export default translation(Sorting);
