import { Field } from 'react-final-form';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import { FormItem } from 'bloko/blocks/form';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Radio from 'bloko/blocks/radio';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';

import FieldWrapper from 'lux/pages/EmployerSearchPublication/components/common/FieldWrapper';
import LabelWrapper from 'lux/pages/EmployerSearchPublication/components/common/LabelWrapper';

const TrlKeys = {
    label: 'vacancy.decor.setting',
    none: 'vacancy.decor.none',
    link: 'vacancy.decor.corporate',
};

const BrandingTemplates: TranslatedComponent = ({ trls }) => {
    const { defaultTemplateName, brandingTemplates } = useSelectorNonNullable(
        (state) => state.employerSearchPublicationSettings
    );
    const isZp = useIsZarplataPlatform();
    const articleUrl = isZp ? '/article/30459' : '/article/brand';

    return (
        <div>
            <VSpacing base={2} />
            <LabelWrapper>{trls[TrlKeys.label]}</LabelWrapper>
            <FieldWrapper>
                <Field name="pageTemplateDefault" initialValue={defaultTemplateName || ''}>
                    {({ input }) => {
                        return (
                            <>
                                {brandingTemplates.length === 0 ? (
                                    <>
                                        <FormItem baseline>
                                            <Text>{trls[TrlKeys.none]}</Text>
                                        </FormItem>
                                        <FormItem baseline>
                                            <BlokoLink
                                                Element={Link}
                                                to={articleUrl}
                                                target="_blank"
                                                disableVisited
                                                appearance={LinkAppearance.Pseudo}
                                            >
                                                {trls[TrlKeys.link]}
                                            </BlokoLink>
                                        </FormItem>
                                    </>
                                ) : (
                                    <>
                                        <FormItem baseline>
                                            <Radio
                                                checked={input.value === ''}
                                                name="pageTemplateDefault"
                                                onChange={() => input.onChange('')}
                                                onFocus={input.onFocus}
                                                onBlur={input.onBlur}
                                                value={''}
                                            >
                                                {trls[TrlKeys.none]}
                                            </Radio>
                                        </FormItem>
                                        {brandingTemplates.map(({ name, title }) => (
                                            <FormItem key={name} baseline>
                                                <Radio
                                                    checked={input.value === name}
                                                    name="pageTemplateDefault"
                                                    onChange={() => input.onChange(name)}
                                                    onFocus={input.onFocus}
                                                    onBlur={input.onBlur}
                                                    value={name}
                                                >
                                                    {title}
                                                </Radio>
                                            </FormItem>
                                        ))}
                                    </>
                                )}
                            </>
                        );
                    }}
                </Field>
            </FieldWrapper>
        </div>
    );
};

export default translation(BrandingTemplates);
