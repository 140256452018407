import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { H1 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

const EmployerManager = ({ employerManagerName }) => {
    if (!employerManagerName) {
        return null;
    }
    return (
        <>
            <H1>{employerManagerName}</H1>
            <VSpacing base={4} />
        </>
    );
};

EmployerManager.propTypes = {
    employerManagerName: PropTypes.string,
};

export default connect(({ employerSettingsMenu: { employerManagerName } }) => ({
    employerManagerName,
}))(EmployerManager);
