import { FC, PropsWithChildren } from 'react';

import Column from 'bloko/blocks/column';

const FieldWrapper: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Column l="7" m="5" s="8" xs="4">
            {children}
        </Column>
    );
};

export default FieldWrapper;
