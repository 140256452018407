import PropTypes from 'prop-types';

import Breadcrumbs from 'lux/components/Breadcrumbs';
import Breadcrumb from 'lux/components/Breadcrumbs/Breadcrumb';
import translation from 'lux/components/translation';

const MenuHeader = ({ employerManagersLink, trls }) => (
    <Breadcrumbs>
        <Breadcrumb to={employerManagersLink}>{trls[MenuHeader.trls.employerQuotasManagers]}</Breadcrumb>
    </Breadcrumbs>
);

MenuHeader.propTypes = {
    /** Ссылка на страницу менеджеров вакансий */
    employerManagersLink: PropTypes.string,
    trls: PropTypes.object,
};

MenuHeader.trls = {
    employerQuotasManagers: 'employer.quotas.managers',
};

export default translation(MenuHeader);
