import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    success: 'employer.settings.message.ok.changed',
    error: 'employer.settings.message.error',
};

const EditSuccess: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.success]}</>;

export const searchPublicationEditSuccess = {
    Element: translation(EditSuccess),
    kind: NotificationKind.Ok,
};

const EditError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.error]}</>;

export const searchPublicationEditError = {
    Element: translation(EditError),
    kind: NotificationKind.Error,
};
