import { Field } from 'react-final-form';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Checkbox from 'bloko/blocks/checkbox';
import { FormItem } from 'bloko/blocks/form';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import FieldWrapper from 'lux/pages/EmployerSearchPublication/components/common/FieldWrapper';
import LabelWrapper from 'lux/pages/EmployerSearchPublication/components/common/LabelWrapper';

const TrlKeys = {
    label: 'employer.settings.searchpublication.t',
    checkbox: 'employer.settings.searchpublication.u',
};

const VacancyTemplateUsage: TranslatedComponent = ({ trls }) => {
    const { useTemplateVacancy: initialValue } = useSelectorNonNullable(
        (state) => state.employerSearchPublicationSettings
    );

    return (
        <div>
            <VSpacing base={2} />
            <LabelWrapper>{trls[TrlKeys.label]}</LabelWrapper>
            <FieldWrapper>
                <Field name="useTemplateVacancy" type="checkbox" initialValue={initialValue}>
                    {({ input }) => (
                        <FormItem baseline>
                            <Checkbox {...input} value={String(input.value)}>
                                {trls[TrlKeys.checkbox]}
                            </Checkbox>
                        </FormItem>
                    )}
                </Field>
            </FieldWrapper>
        </div>
    );
};

export default translation(VacancyTemplateUsage);
