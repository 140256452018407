import { Field } from 'react-final-form';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import Checkbox from 'bloko/blocks/checkbox';
import { FormItem } from 'bloko/blocks/form';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';

import FieldWrapper from 'lux/pages/EmployerSearchPublication/components/common/FieldWrapper';
import LabelWrapper from 'lux/pages/EmployerSearchPublication/components/common/LabelWrapper';

const TrlKeys = {
    label: 'employer.settings.searchpublication.l',
    sendSmsCheckbox: 'employer.settings.searchpublication.s',
    wantWorkCheckbox: 'employer.settings.searchpublication.e',
    wantWorkLink: 'employer.settings.searchpublication.e.link',
    templatesLink: 'employer.settingsmenu.email',
};

const Letters: TranslatedComponent = ({ trls }) => {
    const { useSendSmsToInvite, useWantWorkAutoreply } = useSelectorNonNullable(
        (state) => state.employerSearchPublicationSettings
    );
    const isZp = useIsZarplataPlatform();

    if (isZp) {
        return null;
    }

    return (
        <div>
            <VSpacing base={2} />
            <LabelWrapper
                BottomContent={
                    <FormItem baseline>
                        <BlokoLink
                            Element={Link}
                            to="/employer/settings/email"
                            target="_blank"
                            disableVisited
                            appearance={LinkAppearance.Pseudo}
                        >
                            {trls[TrlKeys.templatesLink]}
                        </BlokoLink>
                    </FormItem>
                }
            >
                {trls[TrlKeys.label]}
            </LabelWrapper>
            <FieldWrapper>
                <Field name="useSendSmsToInvite" type="checkbox" initialValue={useSendSmsToInvite}>
                    {({ input }) => (
                        <FormItem baseline>
                            <Checkbox {...input} value={String(input.value)}>
                                {trls[TrlKeys.sendSmsCheckbox]}
                            </Checkbox>
                        </FormItem>
                    )}
                </Field>
                <Field name="useWantWorkAutoreply" type="checkbox" initialValue={useWantWorkAutoreply}>
                    {({ input }) => (
                        <FormItem baseline>
                            <Checkbox {...input} value={String(input.value)}>
                                {trls[TrlKeys.wantWorkCheckbox]}
                                {NON_BREAKING_SPACE}
                                <BlokoLink Element={Link} to="/employer/targetresumes" target="_blank" disableVisited>
                                    «{trls[TrlKeys.wantWorkLink]}»
                                </BlokoLink>
                            </Checkbox>
                        </FormItem>
                    )}
                </Field>
            </FieldWrapper>
        </div>
    );
};

export default translation(Letters);
