import React from 'react';
import { Form as FinalForm, Field } from 'react-final-form';

import Form from 'lux/components/Form';
import { useSelector } from 'lux/modules/useSelector';

import BrandingTemplates from 'lux/pages/EmployerSearchPublication/components/BrandingTemplates';
import Currency from 'lux/pages/EmployerSearchPublication/components/Currency';
import Editor from 'lux/pages/EmployerSearchPublication/components/Editor';
import Letters from 'lux/pages/EmployerSearchPublication/components/Letters';
import ResumeInSearch from 'lux/pages/EmployerSearchPublication/components/ResumeInSearch';
import Sorting from 'lux/pages/EmployerSearchPublication/components/Sorting';
import SubmitButton from 'lux/pages/EmployerSearchPublication/components/SubmitButton';
import VacancyTemplate from 'lux/pages/EmployerSearchPublication/components/VacancyTemplate';
import VacancyTemplateUsage from 'lux/pages/EmployerSearchPublication/components/VacancyTemplateUsage';
import useSubmitHandler from 'lux/pages/EmployerSearchPublication/hooks/useSubmitHandler';

const FORM_NAME = 'search_publication_form';

const SearchPublicationForm: React.FC = () => {
    const { handleSubmit } = useSubmitHandler();

    const employerManagerIdParam = useSelector((state) => state.router.location.query.employerManagerId);

    return (
        <FinalForm
            onSubmit={handleSubmit}
            render={({ handleSubmit, submitting }) => {
                return (
                    <Form onSubmit={handleSubmit} name={FORM_NAME}>
                        <Field
                            name="employerManagerId"
                            initialValue={employerManagerIdParam}
                            render={({ input }) => <input type="hidden" name={input.name} value={input.value} />}
                        />
                        <ResumeInSearch />
                        <Currency />
                        <Letters />
                        <Sorting />
                        <BrandingTemplates />
                        <Editor />
                        <VacancyTemplateUsage />
                        <VacancyTemplate />
                        <SubmitButton disabled={submitting} />
                    </Form>
                );
            }}
        />
    );
};

export default SearchPublicationForm;
