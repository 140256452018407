import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FieldWrapper from 'lux/components/Employer/ManagerForm/components/common/FieldWrapper';
import LabelWrapper from 'lux/components/Employer/ManagerForm/components/common/LabelWrapper';
import translation from 'lux/components/translation';

const TrlKeys = {
    save: 'Save',
};

interface Props {
    disabled: boolean;
}

const SubmitButton: TranslatedComponent<Props> = ({ trls, disabled }) => {
    const isXs = useBreakpoint() === Breakpoint.XS;

    return (
        <div>
            <VSpacing base={8} />
            <LabelWrapper hideOnXs />
            <FieldWrapper>
                <Button type={ButtonType.Submit} kind={ButtonKind.Primary} stretched={isXs} disabled={disabled}>
                    {trls[TrlKeys.save]}
                </Button>
            </FieldWrapper>
        </div>
    );
};

export default translation(SubmitButton);
