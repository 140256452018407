import React from 'react';

import Column from 'bloko/blocks/column';
import { FormItem } from 'bloko/blocks/form';

const FieldWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <Column xs="4" s="8" m="8" l="8">
            <FormItem>{children}</FormItem>
        </Column>
    );
};

export default FieldWrapper;
