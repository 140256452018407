import React from 'react';

import Column from 'bloko/blocks/column';
import { FormLegend, FormItem, FormRequired } from 'bloko/blocks/form';

const LabelWrapper: React.FC<{ required?: boolean; hideOnXs?: boolean } & React.PropsWithChildren> = ({
    children,
    required = false,
    hideOnXs = false,
}) => {
    return (
        <Column xs={hideOnXs ? '0' : '4'} s="8" m="4" l="4">
            <FormItem>
                <FormLegend>
                    {children}
                    {required && <FormRequired />}
                </FormLegend>
            </FormItem>
        </Column>
    );
};

export default LabelWrapper;
